/*******************************************/
/* GENERAL */
/*******************************************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

:root {
  --main-color: #43affc;
  --secondary-color: #fff;
  --accent-color: #000;
  --grey-color: #ccc;
}

body {
  background-color: var(--main-color);
  font-family: "Poppins", sans-serif;
  color: var(--accent-color);
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 1rem;
}

/* Reusable */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.separator {
  background-color: var(--grey-color);
  height: 1px;
  position: relative;
  margin: 2rem 0;
}

.separator::before {
  content: "or";
  position: absolute;
  position: absolute;
  text-align: center;
  bottom: -1.3rem;
  padding: 0 2rem;
  left: 50%;
  transform: translate(-50%);
  background-color: var(--secondary-color);
  font-size: 2rem;
  color: var(--grey-color);
}
