* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

:root {
  --main-color: #43affc;
  --secondary-color: #fff;
  --accent-color: #000;
  --grey-color: #ccc;
}

body {
  background-color: var(--main-color);
  color: var(--accent-color);
  font-family: Poppins, sans-serif;
}

main {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-bottom: 1rem;
  display: flex;
}

.sub-container {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.separator {
  background-color: var(--grey-color);
  height: 1px;
  margin: 2rem 0;
  position: relative;
}

.separator:before {
  content: "or";
  text-align: center;
  background-color: var(--secondary-color);
  color: var(--grey-color);
  padding: 0 2rem;
  font-size: 2rem;
  position: absolute;
  bottom: -1.3rem;
  left: 50%;
  transform: translate(-50%);
}

/*# sourceMappingURL=index.ca976f67.css.map */
